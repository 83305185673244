@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#branding img {
    position: fixed;
    right: 0px;
    bottom: 0px;
    max-height: 30px;
    background-color: #ececec;
    border-radius: 4px;
}

body {
    font-family: 'Open Sans', serif;
}

h1 {
    text-align: center;
    color: white;
    font-size: 56px;
    font-weight: 800;
    margin-top: 10px;
}

h5 {
    text-align: center;
    color: white;
    margin-top: 45px;
    font-size: 28px;
    font-weight: 600;
}

h6 {
    margin-top: 40px;
    margin-bottom: 0px;
    text-align: center;
    color: white;
    font-size: 20px;
}

.link-text {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    color: white;
    font-size: 18px;
    text-decoration: underline;
}

.img-center {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    width: 20%;
}

.button-center {
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
}
a {
    color: white;
}

a:hover {
    color: white;
}

.color-nav {
    background-color: #BF5700;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 5px;
}

.logo-button {
    margin-right: 7px;
    margin-bottom: 4px;
}

.nav-brand {
    color: white;
    font-family: 'Open Sans', serif;
    font-size: 30px;
    font-weight: 700;
}

.nav-item {
    color: white;
    font-family: 'Open Sans', serif;
    font-size: 22px;
    font-weight: 600;
}

.font-text-trc {
    color: #F5CBA7
}
.leaderboard-table {
    margin: 20px;
}
.activities-dropdown {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 5px;
    display: inline-block;
}

.activities-table {
    margin: 20px;
}

.caret {
display: inline-block;
width: 0;
height: 0;
margin-left: 2px;
vertical-align: middle;
border-top: 4px dashed;
border-top: 4px solid \9;
border-right: 4px solid transparent;
border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
border-top: 0;
border-bottom: 4px dashed;
border-bottom: 4px solid \9;
content: "";
}

.header-class > th:hover {
    cursor: pointer;
    background-color: #e8be9b;
}

.row-class {
    cursor: pointer;
}
.strava_link {
    color: #FC4C02;
    text-decoration: none;
    cursor: pointer;
}

.strava_link:hover {
    color: #FC4C02;
    text-decoration: underline;
    cursor: pointer;
}
.challenges-header {
    text-align: center;
    color: black;
    font-size: 56px;
    font-weight: 800;
    margin-top: 10px;
}

.challenges-description {
    text-align: left;
    color: black;
    margin-top: 30px;
    margin-left: 22px;
    margin-right: 22px;
    margin-bottom: 25px;
    font-size: 21px;
    font-weight: 500;
}

.activities-table {
    margin: 20px;
}
.contests-table {
    margin: 20px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.header-class > th:hover {
    cursor: pointer;
    background-color: #e8be9b;
}

.row-class {
    cursor: pointer;
}
