@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.challenges-header {
    text-align: center;
    color: black;
    font-size: 56px;
    font-weight: 800;
    margin-top: 10px;
}

.challenges-description {
    text-align: left;
    color: black;
    margin-top: 30px;
    margin-left: 22px;
    margin-right: 22px;
    margin-bottom: 25px;
    font-size: 21px;
    font-weight: 500;
}

.activities-table {
    margin: 20px;
}